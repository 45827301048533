import React from "react";

import { jwtDecode } from "jwt-decode";

import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { Field, Form, Formik, FormikHelpers } from "formik";

import { Box, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useMutationPostAuthLogin } from "@/api/auth/mutations";

import MaskedInput from "@/components/Inputs/MaskedInput";
import { TextInput } from "@/components/Inputs/TextInput";
import { CheckboxInput } from "@/components/Inputs/CheckboxInput";

import { useToast } from "@/contexts/ToastContext";

import { JwtPayload } from "@/types/Jwt";
import { ProfileEnum } from "@/enums/Profile";

export interface LoginFormData {
  cpf: string;
  password: string;
  rememberMe: boolean;
}

const initialValues: LoginFormData = {
  cpf: "",
  password: "",
  rememberMe: false,
};

const YupSchema = Yup.object().shape({
  cpf: Yup.string()
    .trim()
    .test("cpf", "O CPF informado é inválido", (value) => {
      if (!value) return false;

      const regex = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/;

      return regex.test(value);
    })
    .required("O campo de CPF é obrigatório"),
  password: Yup.string().trim().required("O campo de senha é obrigatório"),
});

const SignIn: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const { mutateAsync: mutatePostAuthLogin } = useMutationPostAuthLogin();

  const handleOnSubmit = (
    values: LoginFormData,
    { setSubmitting }: FormikHelpers<LoginFormData>
  ) => {
    const { cpf, password, rememberMe } = values;

    mutatePostAuthLogin({ cpf: cpf.replace(/\D/g, ""), password })
      .then((response) => {
        localStorage.removeItem("access-token");
        localStorage.removeItem("refresh-token");
        sessionStorage.removeItem("access-token");
        sessionStorage.removeItem("refresh-token");

        const storage = rememberMe ? localStorage : sessionStorage;

        storage.setItem("access-token", response.accessToken);
        storage.setItem("refresh-token", response.refreshToken);

        const { user_profile } = jwtDecode<JwtPayload>(response.accessToken);
        const profile = ProfileEnum[user_profile];

        const redirects = {
          manager: "/gerenciamento/gestor/dashboard",
          coordinator: "/gerenciamento/coordenador/usuarios",
          researcher: "/pesquisador/escolas",
        };

        if (!redirects[profile as keyof typeof redirects]) {
          toast.error("Perfil de usuário não encontrado!");
          return;
        }

        navigate(redirects[profile as keyof typeof redirects]);
      })
      .catch((error) => {
        toast.error("Ocorreu um erro ao tentar realizar a autenticação!");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={4}
    >
      <Stack direction="column" alignItems="center" gap={2}>
        <Box
          component="img"
          alt="Logo ColetaPNLD"
          src="/assets/images/logo-coletapnld.png"
          width={150}
        />
        <Typography variant="h5" fontWeight="600">
          Olá, seja bem-vindo!
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Insira suas credenciais
        </Typography>
      </Stack>

      <Formik
        initialValues={initialValues}
        validationSchema={YupSchema}
        onSubmit={handleOnSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Box component={Form} width="100%">
            <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
              <Field
                component={TextInput}
                label="CPF"
                name="cpf"
                variant="outlined"
                autoComplete="username"
                InputProps={{
                  inputComponent: MaskedInput,
                  inputProps: {
                    mask: "___.___.___-__",
                    replacement: {
                      _: /[0-9]/,
                    },
                  },
                }}
              />
              <Field
                component={TextInput}
                label="Senha"
                name="password"
                type="password"
                variant="outlined"
                autoComplete="current-password"
              />

              <Stack direction="row" alignItems="center" width="100%">
                <Field
                  component={CheckboxInput}
                  name="rememberMe"
                  type="checkbox"
                  color="primary"
                  label="Lembrar-me"
                  sx={{ paddingLeft: 0 }}
                />
                <Typography variant="body2">Lembrar-se de mim</Typography>
              </Stack>

              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                sx={{ borderRadius: 20 }}
                onClick={submitForm}
                loading={isSubmitting}
              >
                Entrar
              </LoadingButton>
            </Stack>
          </Box>
        )}
      </Formik>
    </Stack>
  );
};

export default SignIn;
