import React, { useState, useEffect } from "react";

import { jwtDecode } from "jwt-decode";

import {
  Avatar,
  Stack,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import { Person2, Logout } from "@mui/icons-material";

import { useMutationPostAuthLogout } from "@/api/auth/mutations";

import { useToast } from "@/contexts/ToastContext";

import { JwtPayload } from "@/types/Jwt";
import { ProfileEnum } from "@/enums/Profile";
import { useNavigate } from "react-router-dom";

interface User {
  email: string;
  profile: string;
}

const ProfileMenu: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [user, setUser] = useState<User>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { mutateAsync: mutatePostAuthLogout } = useMutationPostAuthLogout();

  const handleAvatarMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarMenuClose = () => {
    setAnchorEl(null);
  };

  const getProfileName = (profile?: string) => {
    if (!profile) {
      return "Perfil Inválido";
    }

    switch (profile) {
      case "manager":
        return "Gestor";
      case "coordinator":
        return "Coordenador";
      case "researcher":
        return "Pesquisador";
      default:
        return "Perfil Inválido";
    }
  };

  const handleLogout = () => {
    mutatePostAuthLogout()
      .then(() => {
        toast.success("Usuário deslogado com sucesso!");
      })
      .catch(() => {
        toast.error(
          "Usuário deslogado, porém não foi possível invalidar o token!"
        );
      })
      .finally(() => {
        localStorage.removeItem("access-token");
        localStorage.removeItem("refresh-token");
        sessionStorage.removeItem("access-token");
        sessionStorage.removeItem("refresh-token");

        navigate("/acesso/entrar");
      });
  };

  useEffect(() => {
    const accessToken =
      localStorage.getItem("access-token") ||
      sessionStorage.getItem("access-token") ||
      "";

    if (!accessToken) return;

    try {
      const decoded = jwtDecode<JwtPayload>(accessToken);

      setUser({
        email: decoded.user_email,
        profile: ProfileEnum[decoded.user_profile],
      });
    } catch {}
  }, []);

  return (
    <React.Fragment>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        padding="2px"
        borderRadius={7}
        sx={{
          cursor: "pointer",
          backgroundColor: "grey.700",
        }}
        onClick={handleAvatarMenuOpen}
      >
        <Avatar sx={{ width: 30, height: 30, backgroundColor: "white" }}>
          <Person2 sx={{ color: "grey.700" }} />
        </Avatar>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleAvatarMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        slotProps={{
          paper: {
            style: { transform: "translate(0, 5px)" },
            sx: { borderRadius: 4, border: 1, borderColor: "grey.700" },
          },
        }}
        elevation={0}
      >
        <MenuItem disabled>
          <Typography variant="body1">
            {user?.email || "Email Inválido"}
          </Typography>
        </MenuItem>
        <MenuItem disabled>
          <Typography variant="body1">
            {getProfileName(user?.profile)}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ProfileMenu;
