import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";

import { Stack, Typography, Divider } from "@mui/material";

import { Form as FormType, Question } from "@/types/Form";

import { useToast } from "@/contexts/ToastContext";
import { useModal } from "@/contexts/ModalContext";

import { useMutationPostVisit } from "@/api/visit/mutations";
import { useMutationPostFormAnswer } from "@/api/formAnswer/mutations";

import { getNestedValueString, checkVisibility } from "@/utils/question";

import QuestionRenderer from "@/modules/WithInterview/QuestionRenderer";
import NavigationButtons from "@/modules/WithInterview/NavigationButtons";

export type FormValues = Record<string, any>;

interface InterviewFormProps {
  schoolId: string;
  appointmentId: string;
  form: FormType;
  currentBlock: number;
  setCurrentBlock: React.Dispatch<React.SetStateAction<number>>;
  savedData: { [blockIndex: number]: FormValues };
  setSavedData: React.Dispatch<
    React.SetStateAction<{ [blockIndex: number]: FormValues }>
  >;
}

const InterviewForm: React.FC<InterviewFormProps> = ({
  schoolId,
  appointmentId,
  form,
  currentBlock,
  setCurrentBlock,
  savedData,
  setSavedData,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { openModal } = useModal();

  const [values, setValues] = useState({});

  const questionsBlockRef = React.useRef<HTMLDivElement>(null);

  const { mutateAsync: mutatePostVisit } = useMutationPostVisit();
  const { mutateAsync: mutatePostFormAnswer } = useMutationPostFormAnswer();

  const getLocalStorageKey = (schoolId: string | undefined) => {
    return `interview_${schoolId}`;
  };

  const generateInitialValues = (form: FormType): FormValues => {
    const initialValues: FormValues = {};

    const setNestedValue = (obj: FormValues, path: string, value: any) => {
      const keys = path.split(".");

      let current = obj;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = value;
        } else {
          if (!current[key]) {
            current[key] = {};
          }

          current = current[key];
        }
      });
    };

    const initializeQuestion = (question: Question) => {
      switch (question.type) {
        case "checkboxgroup":
          setNestedValue(initialValues, question.id, []);
          break;
        default:
          setNestedValue(initialValues, question.id, "");
          break;
      }
    };

    form.blocks.forEach((block) => {
      block.questions.forEach((question) => {
        initializeQuestion(question);
      });
    });

    form.blocks.forEach((_, index) => {
      if (savedData[index]) {
        Object.keys(savedData[index]).forEach((key) => {
          setNestedValue(initialValues, key, savedData[index][key]);
        });
      }
    });

    return initialValues;
  };

  const generateValidationSchema = (
    form: FormType
  ): Yup.ObjectSchema<FormValues> => {
    const shape: { [key: string]: any } = {};

    const setNestedValidation = (
      obj: any,
      path: string[],
      schema: Yup.AnySchema
    ) => {
      const [currentKey, ...restPath] = path;

      if (restPath.length === 0) {
        obj[currentKey] = schema;
      } else {
        if (!obj[currentKey]) {
          obj[currentKey] = Yup.object().shape({});
        }

        obj[currentKey] = Yup.object().shape({
          ...obj[currentKey].fields,
          ...setNestedValidation(
            obj[currentKey].fields || {},
            restPath,
            schema
          ),
        });
      }

      return obj;
    };

    const groupedQuestions: { [key: string]: Question[] } = {};

    form.blocks[currentBlock].questions.forEach((question) => {
      const idParts = question.id.split(".");
      const topLevelKey = idParts[0];

      if (!groupedQuestions[topLevelKey]) {
        groupedQuestions[topLevelKey] = [];
      }

      groupedQuestions[topLevelKey].push(question);
    });

    Object.keys(groupedQuestions).forEach((topKey) => {
      const questions = groupedQuestions[topKey];
      let nestedShape: { [key: string]: any } = {};

      questions.forEach((question) => {
        const idParts = question.id.split(".");
        const nestedPath = idParts.slice(1);

        let validator: Yup.AnySchema;

        switch (question.type) {
          case "text":
          case "select":
          case "scale":
          case "checkbox":
            validator = Yup.string();
            break;
          case "checkboxgroup":
            validator = Yup.array().of(Yup.string());
            break;
          case "number":
            validator = Yup.number().typeError("Este campo deve ser um número");
            break;
          default:
            validator = Yup.mixed();
        }

        const isVisible = checkVisibility(question, values);
        if (question.required && isVisible) {
          validator = validator.required("Este campo é obrigatório");

          if (question.type === "checkboxgroup") {
            validator = (validator as Yup.ArraySchema<any, any, any, any>).min(
              1,
              "Selecione ao menos uma opção"
            );
          }
        }

        if (nestedPath.length > 0) {
          setNestedValidation(nestedShape, nestedPath, validator);
        } else {
          nestedShape[topKey] = validator;
        }
      });

      shape[topKey] = Yup.object().shape(nestedShape);
    });

    return Yup.object().shape(shape);
  };

  const handleOnSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    if (questionsBlockRef.current) {
      questionsBlockRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    try {
      const updatedSavedData = { ...savedData, [currentBlock]: values };
      setSavedData(updatedSavedData);

      if (schoolId) {
        localStorage.setItem(
          getLocalStorageKey(schoolId),
          JSON.stringify(updatedSavedData)
        );
      }

      if (currentBlock < form.blocks.length - 1) {
        setCurrentBlock(currentBlock + 1);
        toast.success("Bloco salvo com sucesso!");
      } else {
        openModal({
          title: "Finalizar entrevista",
          description: "Tem certeza que deseja finalizar a entrevista?",
          onConfirmed: () => {
            mutatePostVisit({
              coAppointment: Number(appointmentId),
              stVisitSuccessful: true,
              stInterviewSuccessful: true,
            })
              .then((visit) => {
                const questions = form.blocks.flatMap((block) =>
                  block.questions.map((question) => ({
                    dsQuestionOrder: question.id,
                    dsQuestion: question.text,
                    dsAnswer: getNestedValueString(question.id, values),
                  }))
                );

                const filteredQuestions = questions.filter(
                  (question) => question.dsAnswer !== ""
                );

                mutatePostFormAnswer({
                  coVisit: visit.coVisit,
                  ncoQuestions: filteredQuestions,
                })
                  .then(() => {
                    toast.success("Formulário enviado com sucesso!");

                    if (schoolId) {
                      localStorage.removeItem(getLocalStorageKey(schoolId));
                    }

                    openModal({
                      title: "Entrevista finalizada",
                      description:
                        "Entrevista finalizada com sucesso! Gostaria de registrar o feedback neste momento?",
                      onDenied: () => {
                        navigate("/pesquisador/escolas");
                      },
                      onConfirmed: () => {
                        navigate(
                          `/pesquisador/escola/${schoolId}/visita/${visit.coVisit}/parecer`
                        );
                      },
                    });
                  })
                  .catch(() => {
                    toast.error(
                      "Erro ao salvar a entrevista. Tente novamente mais tarde."
                    );
                  });
              })
              .catch(() => {
                toast.error(
                  "Erro ao salvar a entrevista. Tente novamente mais tarde."
                );
              });
          },
        });
      }
    } catch (error) {
      toast.error("Erro ao salvar o formulário. Tente novamente mais tarde.");
    } finally {
      setSubmitting(false);
    }
  };

  const handleGoBackBlock = () => {
    if (currentBlock > 0) {
      setCurrentBlock(currentBlock - 1);
    }
  };

  const initialValues = generateInitialValues(form);
  const validationSchema = generateValidationSchema(form);

  const isLastBlock = currentBlock === form.blocks.length - 1;

  return (
    <Formik
      innerRef={(formikActions) => {
        if (formikActions) {
          setValues(formikActions.values);
        }
      }}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
      enableReinitialize
      validateOnChange={true}
    >
      {({ submitForm, isSubmitting, values, setFieldValue }) => {
        const questions = form.blocks[currentBlock].questions;

        const visibleQuestions = questions.filter((question) =>
          checkVisibility(question, values)
        );

        const groupedQuestionsArray: {
          blockName: string | null;
          questions: Question[];
        }[] = [];

        let currentGroup: {
          blockName: string | null;
          questions: Question[];
        } = { blockName: null, questions: [] };

        visibleQuestions.forEach((question) => {
          const blockName = question.block || null;
          if (blockName === currentGroup.blockName) {
            currentGroup.questions.push(question);
          } else {
            if (currentGroup.questions.length > 0) {
              groupedQuestionsArray.push(currentGroup);
            }
            currentGroup = { blockName, questions: [question] };
          }
        });

        if (currentGroup.questions.length > 0) {
          groupedQuestionsArray.push(currentGroup);
        }

        return (
          <Form>
            <Stack
              ref={questionsBlockRef}
              direction="column"
              justifyContent="center"
              gap={2}
            >
              <Typography variant="h6" fontWeight="bold">
                {form.blocks[currentBlock].name}
              </Typography>
              {groupedQuestionsArray.map((group, index) => {
                if (group.blockName) {
                  return (
                    <Stack
                      key={`${group.blockName}-${index}`}
                      direction="column"
                      gap={2}
                      sx={{
                        backgroundColor: "grey.200",
                        padding: 2,
                        borderRadius: 4,
                      }}
                    >
                      <Typography variant="h6" fontWeight="bold">
                        {group.blockName}
                      </Typography>
                      <Divider />
                      {group.questions.map((question) => (
                        <QuestionRenderer
                          key={question.id}
                          question={question}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      ))}
                    </Stack>
                  );
                } else {
                  return group.questions.map((question) => (
                    <QuestionRenderer
                      key={question.id}
                      question={question}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ));
                }
              })}
              <NavigationButtons
                isLastBlock={isLastBlock}
                isSubmitting={isSubmitting}
                onSubmit={submitForm}
                onGoBackBlock={handleGoBackBlock}
                showGoBack={currentBlock > 0}
              />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InterviewForm;
