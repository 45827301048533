import React from "react";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { Field, Form, Formik } from "formik";

import { Box, Button, MenuItem, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { statesOptions } from "@/data/states";

import { TextInput } from "@/components/Inputs/TextInput";
import { SelectInput } from "@/components/Inputs/SelectInput";

import { ISchoolFormData } from "./types";

const initialValues: ISchoolFormData = {
  name: "",
  inep_code: "",
  city: "",
  state: "",
  type: "",
};

const YupSchema = Yup.object().shape({
  name: Yup.string().required("O nome é obrigatório"),
  inep_code: Yup.string().required("O código INEP é obrigatório"),
  city: Yup.string().required("A cidade é obrigatória"),
  state: Yup.string().required("O estado é obrigatório"),
  type: Yup.string().required("O tipo é obrigatório"),
});

const Register: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = (values: ISchoolFormData) => {
    // TODO: create school
  };

  return (
    <Stack gap={4}>
      <Typography variant="h4" fontWeight="500">
        Criar escola
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={YupSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Box component={Form} width="100%">
            <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
              <Field
                component={TextInput}
                label="Nome de Usuário"
                name="name"
                variant="outlined"
              />
              <Field
                component={TextInput}
                label="Código INEP"
                name="inep_code"
                variant="outlined"
              />
              <Field
                component={TextInput}
                label="Cidade"
                name="city"
                variant="outlined"
              />
              <Field
                component={SelectInput}
                label="Estado"
                name="state"
                variant="outlined"
                inputProps={{ MenuProps: { style: { maxHeight: 300 } } }}
              >
                {statesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
              <Field
                component={SelectInput}
                label="Tipo"
                name="type"
                variant="outlined"
                inputProps={{ MenuProps: { style: { maxHeight: 300 } } }}
              >
                <MenuItem value="main">Principal</MenuItem>
                <MenuItem value="alternative">Alternativa</MenuItem>
              </Field>
              <Stack direction="row" justifyContent="space-between">
                <Button
                  onClick={() => navigate("/gerenciamento/coordenador/escolas")}
                >
                  Voltar
                </Button>
                <Box>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    sx={{ borderRadius: 20 }}
                    onClick={submitForm}
                    loading={isSubmitting}
                  >
                    Criar
                  </LoadingButton>
                </Box>
              </Stack>
            </Stack>
          </Box>
        )}
      </Formik>
    </Stack>
  );
};

export default Register;
