import React from "react";

import { Box, Typography } from "@mui/material";

import { IInfoBoxProps } from "./types";
import S from "./styles";

const InfoBox: React.FC<IInfoBoxProps> = ({ title, value, percentage }) => {
  return (
    <S.Box>
      <Box>
        <span style={{ fontSize: "1.5rem", fontWeight: "600" }}>{value}</span>{" "}
        <span style={{ fontSize: "1.125rem", fontWeight: "500" }}>
          ({percentage * 100}%)
        </span>
      </Box>
      <Typography variant="h6" fontWeight="500" textAlign="end">
        {title}
      </Typography>
    </S.Box>
  );
};

export default InfoBox;
