import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { ModalProps } from "./types";

const Modal: React.FC<ModalProps> = ({
  title,
  description,
  onClose,
  onDenied,
  onConfirmed,
  ...props
}) => {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      aria-describedby="customized-dialog-description"
      {...props}
    >
      <DialogTitle
        id="customized-dialog-title"
        fontSize={18}
        sx={{ m: 0, p: 2 }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          id="customized-dialog-description"
          textAlign="center"
          gutterBottom
        >
          {description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          variant="contained"
          size="small"
          disableElevation
          onClick={onDenied}
        >
          Não
        </Button>
        <Button
          variant="contained"
          size="small"
          disableElevation
          autoFocus
          onClick={onConfirmed}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
