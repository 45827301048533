import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik, FormikHelpers } from "formik";
import {
  Stack,
  Box,
  Divider,
  IconButton,
  Button,
  Typography,
  FormControl,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import { TextInput } from "@/components/Inputs/TextInput";
import { SelectInput } from "@/components/Inputs/SelectInput";
import { DateTimePickerInput } from "@/components/Inputs/DateTimePickerInput";

import {
  useQuerySchoolResearcher,
  useInfiniteQuerySchoolsResearcher,
} from "@/api/school/queries";
import { useMutationPostAppointmentReplace } from "@/api/appointment/mutations";

import { useToast } from "@/contexts/ToastContext";
import { useModal } from "@/contexts/ModalContext";

import { SchoolStatusEnum } from "@/enums/School";

import { SchoolResearcher } from "@/types/School";

export interface ScheduleFormData {
  personInChargeName: string;
  personInChargePhone: string;
  personInChargeEmail: string;
  personInChargeFunction: string;
  scheduleDate: DateTime | null;
  substitutionSchool: string;
  substitutionReason: string;
}

const initialValues: ScheduleFormData = {
  personInChargeName: "",
  personInChargePhone: "",
  personInChargeEmail: "",
  personInChargeFunction: "",
  scheduleDate: null,
  substitutionSchool: "",
  substitutionReason: "",
};

const YupSchema = Yup.object().shape({
  personInChargeName: Yup.string().required(
    "O Nome do Responsável é obrigatório"
  ),
  personInChargePhone: Yup.string()
    .test(
      "phone",
      "O Telefone do Responsável está no formato inválido, utilize o formato (99) 99999-9999 ou (99) 9999-9999",
      (value) => {
        if (!value) return false;

        const phoneRegex = /^\([1-9]{2}\) [0-9]{4,5}-[0-9]{4}$/;
        return phoneRegex.test(value);
      }
    )
    .required("O Telefone do Responsável é obrigatório"),
  personInChargeEmail: Yup.string().email("O E-mail do Responsável é inválido"),
  personInChargeFunction: Yup.string().required(
    "A Função do Responsável é obrigatória"
  ),
  scheduleDate: Yup.date()
    .nullable()
    .required("A Data da Entrevista é obrigatória"),
  substitutionSchool: Yup.string().required(
    "A Escola Substituta é obrigatória"
  ),
  substitutionReason: Yup.string().required(
    "O Motivo da Substituição é obrigatório"
  ),
});

const Replace: React.FC = () => {
  const toast = useToast();

  const params = useParams<{ idSchool: string; idAppointment: string }>();
  const navigate = useNavigate();

  const { openModal } = useModal();

  const [allOptionsSchools, setAllOptionsSchools] =
    useState<SchoolResearcher[]>();

  const { data: schoolResearcher, isLoading: isLoadingSchoolResearcher } =
    useQuerySchoolResearcher({
      coSchool: Number(params.idSchool),
    });
  const {
    data: infiniteSchoolsResearcherPending,
    isLoading: isLoadingInfiniteSchoolsResearcherPending,
  } = useInfiniteQuerySchoolsResearcher({
    stResearchStatus: SchoolStatusEnum.pending,
  });

  const { mutateAsync: mutatePostAppointmentReplace } =
    useMutationPostAppointmentReplace();

  const handleGoBack = () => {
    navigate("/pesquisador/escolas");
  };

  const handleOnSubmit = (
    values: ScheduleFormData,
    { setSubmitting }: FormikHelpers<ScheduleFormData>
  ) => {
    const {
      personInChargeName,
      personInChargePhone,
      personInChargeEmail,
      personInChargeFunction,
      scheduleDate,
      substitutionSchool,
      substitutionReason,
    } = values;

    openModal({
      title: "Agendar Entrevista",
      description: substitutionSchool
        ? "Deseja confirmar o agendamento da entrevista com a escola substituta?"
        : "Deseja confirmar o agendamento da entrevista com a escola selecionada?",
      onConfirmed: () => {
        if (!scheduleDate) return;

        const formattedDate = scheduleDate.toFormat("yyyy-MM-dd");
        const formattedTime = scheduleDate.toFormat("HH:mm");

        mutatePostAppointmentReplace({
          coAppointment: Number(params.idAppointment),
          dtAppointment: formattedDate,
          dtTime: formattedTime,
          dsPersonInCharge: personInChargeName,
          dsPhonePinCharge: personInChargePhone,
          dsEmailPinCharge: personInChargeEmail,
          dsFunctionPinCharge: personInChargeFunction,
          coSubstitutionSchool: Number(substitutionSchool),
          dsSubstitutionReason: substitutionReason,
        })
          .then(() => {
            toast.success("Entrevista agendada com sucesso");

            navigate("/pesquisador/escolas");
          })
          .catch(() => {
            toast.error("Ocorreu um erro ao agendar a entrevista");
          });
      },
    });

    setSubmitting(false);
  };

  useEffect(() => {
    if (!infiniteSchoolsResearcherPending) return;

    const { idSchool } = params;

    const flatSchoolsResearcherPending =
      infiniteSchoolsResearcherPending.pages.flatMap((page) => page.results);

    const filteredSchools = flatSchoolsResearcherPending.filter(
      (school) => school.coSchool !== Number(idSchool)
    );

    setAllOptionsSchools(filteredSchools);
  }, [infiniteSchoolsResearcherPending]);

  if (isLoadingSchoolResearcher || isLoadingInfiniteSchoolsResearcherPending) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        height="100%"
        spacing={2}
      >
        <CircularProgress size="2rem" />
        <Typography>Carregando...</Typography>
      </Stack>
    );
  }

  return (
    <Box height="auto" padding={3}>
      <Stack
        position="relative"
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <IconButton
          onClick={handleGoBack}
          sx={{ position: "absolute", left: 0 }}
        >
          <ArrowBack fontSize="small" />
        </IconButton>
        <Typography variant="h5" fontWeight="500">
          Substituir Entrevista
        </Typography>
      </Stack>
      <Divider sx={{ width: "100%", marginY: 2 }} />
      <Stack textAlign="left" spacing={2} marginBottom={2}>
        <Typography
          variant="h6"
          fontWeight="bold"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {schoolResearcher?.dsName}
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Localização:
          </Typography>{" "}
          {schoolResearcher?.dsCity ?? "Cidade não informada"} -{" "}
          {schoolResearcher?.dsState ?? "Estado não informado"}
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Código INEP:
          </Typography>{" "}
          {schoolResearcher?.dsInepCode}
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Telefone(s):
          </Typography>{" "}
          {schoolResearcher?.dsPhone ?? "Não informado"}
        </Typography>
        <Divider />
        <Typography variant="subtitle1" fontWeight="bold">
          Dados do Agendamento Original:
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Data da Entrevista:
          </Typography>{" "}
          {schoolResearcher?.appointment?.dtAppointment
            ? DateTime.fromISO(
                schoolResearcher.appointment?.dtAppointment
              ).toFormat("dd/MM/yyyy")
            : "Não informada"}
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Horário da Entrevista:
          </Typography>{" "}
          {schoolResearcher?.appointment?.dtTime
            ? DateTime.fromISO(schoolResearcher.appointment?.dtTime).toFormat(
                "HH:mm"
              )
            : "Não informado"}
        </Typography>
        <Divider />
        <Typography variant="subtitle1" fontWeight="bold">
          Dados do Agendamento Substituto:
        </Typography>
      </Stack>
      <Formik
        initialValues={initialValues}
        validationSchema={YupSchema}
        onSubmit={handleOnSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Box component={Form} width="100%">
            <Stack direction="column" justifyContent="center" gap={2}>
              <Field
                component={TextInput}
                name="personInChargeName"
                label="Nome do Responsável"
                autoComplete="off"
                fullWidth
                required
              />
              <Field
                component={TextInput}
                name="personInChargePhone"
                label="Telefone do Responsável"
                autoComplete="off"
                fullWidth
                required
              />
              <Field
                component={TextInput}
                type="email"
                name="personInChargeEmail"
                label="E-mail do Responsável"
                autoComplete="off"
                fullWidth
              />
              <FormControl fullWidth>
                <Field
                  component={SelectInput}
                  name="personInChargeFunction"
                  label="Função do Responsável"
                  required
                >
                  <MenuItem value="Diretor">Diretor</MenuItem>
                  <MenuItem value="Coordenador">Coordenador</MenuItem>
                  <MenuItem value="Secretário">Secretário</MenuItem>
                  <MenuItem value="Outro">Outro</MenuItem>
                </Field>
              </FormControl>
              <FormControl fullWidth>
                <Field
                  component={DateTimePickerInput}
                  name="scheduleDate"
                  label="Data e Hora do Agendamento"
                  required
                />
              </FormControl>
              <FormControl fullWidth>
                <Field
                  component={SelectInput}
                  name="substitutionSchool"
                  label="Escola Substituta"
                  disabled={!allOptionsSchools?.length}
                  required
                >
                  <MenuItem value="">Nenhuma</MenuItem>
                  <Divider />
                  {allOptionsSchools?.map((school) => (
                    <MenuItem key={school.coSchool} value={school.coSchool}>
                      {school.dsName} - {school.dsInepCode}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              <Field
                component={TextInput}
                name="substitutionReason"
                label="Justificativa da Substituição"
                autoComplete="off"
                fullWidth
                required
              />
              <Button
                variant="contained"
                onClick={submitForm}
                disabled={isSubmitting}
                disableElevation
                fullWidth
              >
                Confirmar Substituição
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default Replace;
