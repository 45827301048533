import React, { useEffect } from "react";

import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableFooter,
  Checkbox,
  Typography,
  Box,
  Stack,
  TableContainer,
  Paper,
  TableCell,
} from "@mui/material";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  RowData,
  ColumnDef,
  CellContext,
  ColumnMeta,
} from "@tanstack/react-table";

import S from "./styles";
import { IDataTableProps, IHeader, TRow } from "./types";

function DataTable({
  data,
  headers,
  isLoading,
  selectableRow,
  onRowSelectionChange,
  rowId = "id",
  onRowClick,
}: IDataTableProps<RowData>) {
  const [tableData, setTableData] = React.useState(data);
  const [columns, setColumns] = React.useState<Array<ColumnDef<RowData>>>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [allRows, setAllRows] = React.useState<Array<RowData>>([]);
  const [isSubHeader, setIsSubHeader] = React.useState(false);

  const selectableColumn = React.useMemo<ColumnDef<RowData>>(() => {
    return {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <Box>
          <Checkbox
            checked={row.getIsSelected()}
            disabled={row.getCanSelect()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        </Box>
      ),
    };
  }, []);

  const tableCell = ({
    getValue,
    row,
    column,
    table,
  }: CellContext<unknown, unknown>) => {
    const initialValue = getValue();
    const columnMeta = column.columnDef.meta;
    const cellType: string = columnMeta?.type ?? "default";
    const [value, setValue] = React.useState(initialValue);

    const onBlur = () => {
      table.options.meta?.updateData(row.index, column.id, value);
    };

    const cells = {
      element: <>{value}</>,
      text: <Typography>{String(value || "")}</Typography>,
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      (cells as Record<string, JSX.Element | undefined>)[cellType] || cells.text
    );
  };

  const table = useReactTable({
    data: tableData,
    columns,
    enableRowSelection: true,
    getRowId: (row) => String((row as TRow)[rowId]),
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      rowSelection,
    },
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setTableData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...(old[rowIndex] as Record<string, unknown>),
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
  });

  useEffect(() => {
    formatColumns(headers);
  }, []);

  useEffect(() => {
    setAllRows((prevRows) => {
      const newRows = [...prevRows];

      data.forEach((row) => {
        const existingRow = newRows.find(
          (r) => (r as TRow)[rowId] === (row as TRow)[rowId]
        );
        if (!existingRow) {
          newRows.push(row);
        } else {
          Object.assign(existingRow, row);
        }
      });
      return newRows;
    });
    setTableData(data);
  }, [data]);

  useEffect(() => {
    onRowSelectionChange?.(getSelectedRows());
  }, [rowSelection]);

  const formatColumns = (columns: Array<IHeader>) => {
    const haveSubHeader = columns.some((column) => column.subHeader);
    setIsSubHeader(haveSubHeader);
    const formattedColumns: Array<ColumnDef<RowData>> = columns.map(
      (column) => {
        if (haveSubHeader) {
          return {
            header: column.name,
            accessorKey: column.subHeader ? `sub${column.key}` : column.key,
            cell: (props) => tableCell(props),
            footer: column.footer,
            meta: {
              type: column.type,
              width: column.width,
              align: column.align,
            } as ColumnMeta<object, string | number>,
            columns: [
              ...(column.subHeader
                ? column.subHeader.map((subHeader) => ({
                    header: subHeader.name,
                    accessorKey: subHeader.key,
                    footer: subHeader.footer,
                    meta: {
                      type: subHeader.type,
                      width: subHeader.width,
                      align: subHeader.align,
                    } as ColumnMeta<object, string | number>,
                  }))
                : [
                    {
                      header: "",
                      accessorKey: column.key,
                      meta: {
                        type: column.type,
                        width: column.width,
                        align: column.align,
                      } as ColumnMeta<object, string | number>,
                    },
                  ]),
            ],
          };
        }

        return {
          header: column.name,
          accessorKey: column.key,
          cell: (props) => tableCell(props),
          footer: column.footer,
          meta: {
            type: column.type,
            width: column.width,
            align: column.align,
          } as ColumnMeta<object, string | number>,
        };
      }
    );

    if (selectableRow) {
      formattedColumns.unshift(selectableColumn);
    }

    setColumns(formattedColumns);
  };

  const getSelectedRows = () => {
    const selectedRowsId = Object.keys(rowSelection);
    const selectedRows = allRows.filter((row) => {
      const id = String((row as TRow)[rowId]);
      return selectedRowsId.includes(id);
    });

    return selectedRows;
  };

  const calculateBg = (index: number, isHeader = false) => {
    const isEvenIndex = index % 2 === 0;
    const baseColor = isEvenIndex ? "gray.100" : "white";

    if (!isHeader) {
      return baseColor;
    }

    if (isSubHeader) {
      return baseColor;
    }

    return baseColor === "gray.100" ? "white" : "gray.100";
  };

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup, index) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCell
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      sx={{
                        width: header.column.columnDef.meta?.width,
                      }}
                    >
                      <Stack
                        flexDirection="row"
                        justifyContent={header.column.columnDef.meta?.align}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted() ? (
                          <Box>
                            {header.column.getIsSorted() === "desc" ? (
                              <ArrowDropDown aria-label="sorted descending" />
                            ) : (
                              <ArrowDropUp aria-label="sorted ascending" />
                            )}
                          </Box>
                        ) : null}
                      </Stack>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row, index) => (
              <TableRow
                key={row.id}
                onClick={() => onRowClick?.(row)}
                sx={{
                  backgroundColor: calculateBg(index),
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id} color="gray.700">
                      <Stack
                        flexDirection="row"
                        justifyContent={cell.column.columnDef.meta?.align}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Stack>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            {table.getFooterGroups().map((footerGroup) => (
              <TableRow key={footerGroup.id}>
                {footerGroup.headers.map((header) => {
                  const value = flexRender(
                    header.column.columnDef.footer,
                    header.getContext()
                  );

                  return (
                    <TableCell
                      key={header.id}
                      sx={{
                        px: !value ? 0 : 4,
                        py: !value ? 0 : 1,
                      }}
                    >
                      {header.isPlaceholder ? null : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default DataTable;
