import http from '@/api/http'

import { PaginatedResponse } from '@/types/Pagination'
import { getSchoolsResearcherPayload, getSchoolsResearcherResponse, getSchoolResearcherPayload, getSchoolResearcherResponse } from './types'

export const getSchoolsResearcher = async (payload: getSchoolsResearcherPayload): Promise<getSchoolsResearcherResponse> => {
  const response = await http.get<getSchoolsResearcherResponse>('/school/researcher/', {
    params: payload,
  })

  return response.data
}

export const getSchoolResearcher = async (payload: getSchoolResearcherPayload): Promise<getSchoolResearcherResponse> => {
  const { coSchool } = payload

  const response = await http.get<PaginatedResponse<getSchoolResearcherResponse>>('/school/researcher/', {
    params: { coSchool },
  })

  return response.data.results[0]
}
