import React from "react";

import { DateTime } from "luxon";

import { useNavigate, useParams } from "react-router-dom";

import * as Yup from "yup";
import { Field, Form, Formik, FormikHelpers } from "formik";

import {
  Stack,
  Box,
  Divider,
  IconButton,
  Button,
  Typography,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import { TextInput } from "@/components/Inputs/TextInput";
import { DateTimePickerInput } from "@/components/Inputs/DateTimePickerInput";

import { useQuerySchoolResearcher } from "@/api/school/queries";
import { useMutationPostVisit } from "@/api/visit/mutations";

import { useToast } from "@/contexts/ToastContext";

export interface ScheduleFormData {
  visitDate: DateTime | null;
  withoutInterviewReason: string;
}

const initialValues: ScheduleFormData = {
  visitDate: null,
  withoutInterviewReason: "",
};

const YupSchema = Yup.object().shape({
  visitDate: Yup.date().required("A Data da Visita é obrigatória"),
  withoutInterviewReason: Yup.string().required(
    "A Justificativa da Impossibilidade da Entrevista é obrigatória"
  ),
});

const WithoutInterview: React.FC = () => {
  const toast = useToast();

  const params = useParams();
  const navigate = useNavigate();

  const { data: schoolResearcher, isLoading: isLoadingSchoolResearcher } =
    useQuerySchoolResearcher({
      coSchool: Number(params.idSchool),
    });

  const { mutateAsync: mutatePostVisit } = useMutationPostVisit();

  const handleGoBack = () => {
    navigate("/pesquisador/escolas");
  };

  const handleOnSubmit = (
    values: ScheduleFormData,
    { setSubmitting }: FormikHelpers<ScheduleFormData>
  ) => {
    const { visitDate, withoutInterviewReason } = values;

    if (!visitDate) {
      setSubmitting(false);
      return;
    }

    const formattedDate = visitDate.toFormat("yyyy-MM-dd");
    const formattedTime = visitDate.toFormat("HH:mm");

    mutatePostVisit({
      coAppointment: Number(params.idAppointment),
      stVisitSuccessful: true,
      stInterviewSuccessful: false,
      dtVisit: formattedDate,
      dtStartTime: formattedTime,
      dtEndTime: formattedTime,
      dtInterviewNotes: withoutInterviewReason,
    })
      .then(() => {
        toast.success("Visita registrada com sucesso!");

        navigate("/pesquisador/escolas");
      })
      .catch(() => {
        toast.error("Erro ao registrar a visita. Tente novamente mais tarde.");
      });

    setSubmitting(false);
  };

  if (isLoadingSchoolResearcher) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        height="100%"
        spacing={2}
      >
        <CircularProgress size="2rem" />
        <Typography>Carregando...</Typography>
      </Stack>
    );
  }

  return (
    <Box height="auto" padding={3}>
      <Stack
        position="relative"
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <IconButton
          onClick={handleGoBack}
          sx={{ position: "absolute", left: 0 }}
        >
          <ArrowBack fontSize="small" />
        </IconButton>
        <Typography variant="h5" fontWeight="500">
          Visita Sem Entrevista
        </Typography>
      </Stack>
      <Divider sx={{ width: "100%", marginY: 2 }} />
      <Stack textAlign="left" spacing={1}>
        <Typography
          variant="h6"
          fontWeight="bold"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {schoolResearcher?.dsName}
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Localização:
          </Typography>{" "}
          {schoolResearcher?.dsCity ?? "Cidade não informada"} -{" "}
          {schoolResearcher?.dsState ?? "Estado não informado"}
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Código INEP:
          </Typography>{" "}
          {schoolResearcher?.dsInepCode}
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Telefone(s):
          </Typography>{" "}
          {schoolResearcher?.dsPhone ?? "Não informado"}
        </Typography>
        <Divider />
        <Typography variant="subtitle1" fontWeight="bold">
          Dados do Agendamento:
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Data da Entrevista:
          </Typography>{" "}
          {schoolResearcher?.appointment?.dtAppointment
            ? DateTime.fromISO(
                schoolResearcher.appointment?.dtAppointment
              ).toFormat("dd/MM/yyyy")
            : "Não informada"}
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Horário da Entrevista:
          </Typography>{" "}
          {schoolResearcher?.appointment?.dtTime
            ? DateTime.fromISO(schoolResearcher.appointment?.dtTime).toFormat(
                "HH:mm"
              )
            : "Não informado"}
        </Typography>
      </Stack>
      <Divider sx={{ width: "100%", marginY: 2 }} />
      <Formik
        initialValues={initialValues}
        validationSchema={YupSchema}
        onSubmit={handleOnSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Box component={Form} width="100%">
            <Stack direction="column" justifyContent="center" gap={2}>
              <FormControl fullWidth>
                <Field
                  component={DateTimePickerInput}
                  name="visitDate"
                  label="Data e Hora da Visita"
                  required
                />
              </FormControl>
              <Field
                component={TextInput}
                name="withoutInterviewReason"
                label="Justifique a impossibilidade da entrevista"
                variant="outlined"
                autoComplete="off"
                fullWidth
                multiline
                required
                rows={4}
              />
              <Button
                variant="contained"
                disabled={isSubmitting}
                fullWidth
                disableElevation
                onClick={submitForm}
              >
                Registrar Visita
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default WithoutInterview;
