import React from "react";
import { useNavigate } from "react-router-dom";
import { Block, Edit, Autorenew } from "@mui/icons-material";

import {
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
  Select,
  SelectChangeEvent,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Divider,
} from "@mui/material";

import DataTable from "@/common/components/DataTable";

const UserActions = ({ id }: { id: number }) => {
  const navigate = useNavigate();

  const handleBlock = () => {
    // TODO: Block user with id
  };

  const handleEdit = () => {
    navigate(`/gerenciamento/gestor/usuario/editar/${id}`);
  };

  const handleReset = () => {
    // TODO: Reset user password with id
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
      sx={{ height: "100%" }}
    >
      <IconButton color="error" onClick={handleBlock}>
        <Block />
      </IconButton>
      <IconButton color="warning" onClick={handleEdit}>
        <Edit />
      </IconButton>
      <IconButton color="success" onClick={handleReset}>
        <Autorenew />
      </IconButton>
    </Stack>
  );
};

const headers = [
  {
    name: "Nome",
    key: "name",
  },
  {
    name: "E-mail",
    key: "email",
  },
  {
    name: "Cidade",
    key: "city",
  },
  {
    name: "Estado",
    key: "state",
  },
  {
    name: "Tipo",
    key: "type",
  },
  {
    name: "Ações",
    key: "actions",
    type: "element",
    align: "center",
  },
];

const View: React.FC = () => {
  const navigate = useNavigate();
  const [selectedState, setSelectedState] = React.useState<string>("");
  const [search, setSearch] = React.useState<string>("");

  const tableData = [
    {
      id: 15,
      name: "João da Silva",
      email: "jao@email.com",
      city: "São Paulo",
      state: "SP",
      type: "Pesquisador",
      actions: <UserActions id={15} />,
    },
    {
      id: 232,
      name: "Maria da Silva",
      email: "maria@email.com",
      city: "São Paulo",
      state: "SP",
      type: "Pesquisador",
      actions: <UserActions id={232} />,
    },
  ];

  const createCoordinator = () => {
    navigate("/gerenciamento/gestor/usuario/criar");
  };

  return (
    <Stack gap={3} borderRadius="12px" bgcolor="white" sx={{ boxShadow: 2 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="end"
        pt={2}
        px={2}
      >
        <Typography variant="h4" fontWeight="500">
          Usuários
        </Typography>
        <Box>
          <Button variant="contained" size="small" onClick={createCoordinator}>
            Cadastrar Usuário
          </Button>
        </Box>
      </Stack>
      <Divider />
      <Stack gap={2} p={2}>
        <Stack direction="row" gap={1}>
          <FormControl sx={{ flex: 1 }}>
            <InputLabel id="state">Filtrar por estado</InputLabel>
            <Select
              value={selectedState}
              id="state"
              label="Filtrar por estado"
              onChange={(e: SelectChangeEvent) =>
                setSelectedState(e.target.value)
              }
              MenuProps={{
                style: { maxHeight: 300 },
              }}
            >
              <MenuItem value="SP">São Paulo</MenuItem>
              <MenuItem value="RJ">Rio de Janeiro</MenuItem>
              <MenuItem value="MG">Minas Gerais</MenuItem>
              <MenuItem value="RS">Rio Grande do Sul</MenuItem>
            </Select>
          </FormControl>
          <TextField
            value={search}
            label="Busque por nome ou cidade"
            sx={{ flex: 3 }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Stack>
        <DataTable headers={headers} data={tableData} />
      </Stack>
    </Stack>
  );
};

export default View;
