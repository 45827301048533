import React, { useEffect } from "react";

import { Outlet, useLocation } from "react-router-dom";

import { Link, Stack, Box } from "@mui/material";

import ProfileMenu from "@/common/components/ProfileMenu";

const TemplateUser: React.FC = () => {
  const location = useLocation();

  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [location]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100svh"
      bgcolor="white"
      padding={2}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        padding={2}
        paddingTop={0}
      >
        <Link href="/pesquisador" underline="none">
          <Box
            component="img"
            alt="Logo ColetaPNLD"
            src="/assets/images/logo-coletapnld.png"
            height={60}
          />
        </Link>
        <ProfileMenu />
      </Stack>
      <Box
        ref={scrollContainerRef}
        bgcolor="background.default"
        width="100%"
        maxWidth="xl"
        borderRadius={4}
        flexGrow={1}
        overflow="auto"
      >
        <Outlet />
      </Box>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        padding={2}
        paddingBottom={0}
        gap={2}
      >
        <Box
          component="img"
          alt="Logo FNDE"
          src="/assets/images/logo-fnde.png"
          sx={{
            objectFit: "cover",
            height: {
              xs: 40,
              sm: 50,
            },
          }}
        />
        <Box
          component="img"
          alt="Logo NEES"
          src="/assets/images/logo-nees.png"
          sx={{
            objectFit: "cover",
            height: {
              xs: 40,
              sm: 50,
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default TemplateUser;
