import { AxiosError } from 'axios';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  QueryFunction,
  InfiniteData,
} from '@tanstack/react-query';

import { getSchoolsResearcher, getSchoolResearcher } from './services';
import {
  getSchoolsResearcherPayload,
  getSchoolsResearcherResponse,
  getSchoolResearcherPayload,
  getSchoolResearcherResponse,
} from './types';

export const useQuerySchoolsResearcher = (
  payload: getSchoolsResearcherPayload,
  options?: Omit<
    UseQueryOptions<getSchoolsResearcherResponse, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const queryKey: QueryKey = ['schools-researcher', payload];
  const queryFn = () => getSchoolsResearcher(payload);

  return useQuery<getSchoolsResearcherResponse, AxiosError>({
    queryKey,
    queryFn,
    ...options,
  });
};

export const useInfiniteQuerySchoolsResearcher = (
  payload: Omit<getSchoolsResearcherPayload, 'page' | 'pageSize'>,
  options?: Omit<
    UseInfiniteQueryOptions<
      getSchoolsResearcherResponse,
      AxiosError,
      InfiniteData<getSchoolsResearcherResponse>
    >,
    'queryKey' | 'queryFn' | 'getNextPageParam'
  >,
) => {
  const queryKey: QueryKey = ['schools-researcher-infinite', payload];

  const queryFn: QueryFunction<getSchoolsResearcherResponse, QueryKey, unknown> = ({ pageParam = 1 }) =>
    getSchoolsResearcher({ ...payload, page: pageParam as number, pageSize: 10 });

  return useInfiniteQuery<
    getSchoolsResearcherResponse,
    AxiosError,
    InfiniteData<getSchoolsResearcherResponse>
  >({
    queryKey,
    queryFn,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.pagination.currentPage < lastPage.pagination.totalPages) {
        return lastPage.pagination.currentPage + 1;
      }
      return undefined;
    },
    ...options,
  });
};

export const useQuerySchoolResearcher = (
  payload: getSchoolResearcherPayload,
  options?: Omit<
    UseQueryOptions<getSchoolResearcherResponse, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const queryKey: QueryKey = ['school-researcher', payload];
  const queryFn = () => getSchoolResearcher(payload);

  return useQuery<getSchoolResearcherResponse, AxiosError>({
    queryKey,
    queryFn,
    ...options,
  });
};
