import React from "react";

import { DateTime } from "luxon";

import { useNavigate, useParams } from "react-router-dom";

import * as Yup from "yup";
import { Field, Form, Formik, FormikHelpers } from "formik";

import {
  Stack,
  Box,
  Divider,
  IconButton,
  Button,
  Typography,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import { TextInput } from "@/components/Inputs/TextInput";
import { TimePickerInput } from "@/components/Inputs/TimePickerInput";
import { CheckboxGroupInput } from "@/components/Inputs/CheckboxGroupInput";

import { useQuerySchoolResearcher } from "@/api/school/queries";
import { useMutationPostFeedback } from "@/api/feedback/mutations";

import { useToast } from "@/contexts/ToastContext";

export interface ScheduleFormData {
  startTime: DateTime | null;
  endTime: DateTime | null;
  availability: string;
  interruptions: string;
  infrastructure: string;
  documents: string;
  collaborative: string;
  duration: string;
  prepared: string;
  support: string;
  questionsEfficiency: string;
  difficulties: string;
  additionalComments: string;
}

const initialValues: ScheduleFormData = {
  startTime: null,
  endTime: null,
  availability: "",
  interruptions: "",
  infrastructure: "",
  documents: "",
  collaborative: "",
  duration: "",
  prepared: "",
  support: "",
  questionsEfficiency: "",
  difficulties: "",
  additionalComments: "",
};

const YupSchema = Yup.object().shape({
  startTime: Yup.mixed()
    .nullable()
    .required("O Horário de Início é obrigatório"),
  endTime: Yup.mixed()
    .nullable()
    .required("O Horário de Término é obrigatório")
    .test(
      "is-greater-than-startTime",
      "O Horário de Término deve ser maior que o Horário de Início",
      function (value) {
        const { startTime } = this.parent;

        if (value && startTime) {
          return value > startTime;
        }

        return true;
      }
    ),
  availability: Yup.string().required(
    "A Pergunta sobre a Disponibilidade é obrigatória"
  ),
  interruptions: Yup.string().required(
    "A Pergunta sobre as Interrupções é obrigatória"
  ),
  infrastructure: Yup.string().required(
    "A Pergunta sobre a Infraestrutura é obrigatória"
  ),
  documents: Yup.string().required(
    "A Pergunta sobre os Documentos é obrigatória"
  ),
  collaborative: Yup.string().required(
    "A Pergunta sobre a Colaboração é obrigatória"
  ),
  duration: Yup.string().required("A Pergunta sobre a Duração é obrigatória"),
  prepared: Yup.string().required(
    "A Pergunta sobre a Preparação é obrigatória"
  ),
  support: Yup.string().required("A Pergunta sobre o Suporte é obrigatória"),
  questionsEfficiency: Yup.string(),
  difficulties: Yup.string(),
  additionalComments: Yup.string(),
});

const Feedback: React.FC = () => {
  const toast = useToast();

  const params = useParams();
  const navigate = useNavigate();

  const { data: schoolResearcher, isLoading: isLoadingSchoolResearcher } =
    useQuerySchoolResearcher({
      coSchool: Number(params.idSchool),
    });

  const { mutateAsync: mutatePostFeedback } = useMutationPostFeedback();

  const convertStringToBoolean = (value: string) => {
    return value === "true";
  };

  const handleGoBack = () => {
    navigate("/pesquisador/escolas");
  };

  const handleOnSubmit = (
    values: ScheduleFormData,
    { setSubmitting }: FormikHelpers<ScheduleFormData>
  ) => {
    const {
      startTime,
      endTime,
      availability,
      interruptions,
      infrastructure,
      documents,
      collaborative,
      duration,
      prepared,
      support,
      questionsEfficiency,
      difficulties,
      additionalComments,
    } = values;

    if (!startTime || !endTime) {
      toast.error("Os horários de início e término são obrigatórios.");
      return;
    }

    const formattedStartTime = startTime.toFormat("HH:mm");
    const formattedEndTime = endTime.toFormat("HH:mm");

    mutatePostFeedback({
      coVisit: Number(params.idVisit),
      dtStartTime: formattedStartTime,
      dtEndTime: formattedEndTime,
      stPrincipalPresence: convertStringToBoolean(availability),
      stInterruption: convertStringToBoolean(interruptions),
      stAdequateInfrastructure: convertStringToBoolean(infrastructure),
      stRequestedDocuments: convertStringToBoolean(documents),
      stCollaborativePrincipal: convertStringToBoolean(collaborative),
      stAdequateDuration: convertStringToBoolean(duration),
      stPrepared: convertStringToBoolean(prepared),
      stReceivedSupport: convertStringToBoolean(support),
      dsEvaluation: questionsEfficiency,
      dsDifficulties: difficulties,
      dsComments: additionalComments,
    })
      .then(() => {
        toast.success("Feedback registrado com sucesso!");

        navigate("/pesquisador/escolas");
      })
      .catch(() => {
        toast.error(
          "Erro ao registrar o feedback. Tente novamente mais tarde."
        );
      });

    setSubmitting(false);
  };

  if (isLoadingSchoolResearcher) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        height="100%"
        spacing={2}
      >
        <CircularProgress size="2rem" />
        <Typography>Carregando...</Typography>
      </Stack>
    );
  }

  return (
    <Box height="auto" padding={3}>
      <Stack
        position="relative"
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <IconButton
          onClick={handleGoBack}
          sx={{ position: "absolute", left: 0 }}
        >
          <ArrowBack fontSize="small" />
        </IconButton>
        <Typography variant="h5" fontWeight="500">
          Registrar Feedback
        </Typography>
      </Stack>
      <Divider sx={{ width: "100%", marginY: 2 }} />
      <Stack textAlign="left" spacing={2} marginBottom={2}>
        <Typography
          variant="h6"
          fontWeight="bold"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {schoolResearcher?.dsName}
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Localização:
          </Typography>{" "}
          {schoolResearcher?.dsCity ?? "Cidade não informada"} -{" "}
          {schoolResearcher?.dsState ?? "Estado não informado"}
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Código INEP:
          </Typography>{" "}
          {schoolResearcher?.dsInepCode}
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Telefone(s):
          </Typography>{" "}
          {schoolResearcher?.dsPhone ?? "Não informado"}
        </Typography>
        <Divider />
        <Typography variant="subtitle1" fontWeight="bold">
          Entrevista Registrada Em:
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Data da Entrevista:
          </Typography>{" "}
          {schoolResearcher?.visit?.dtVisit
            ? DateTime.fromISO(schoolResearcher.visit?.dtVisit).toFormat(
                "dd/MM/yyyy"
              )
            : "Não informada"}
        </Typography>
        <Typography variant="subtitle1">
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            Horário da Entrevista:
          </Typography>{" "}
          {schoolResearcher?.visit?.dtStartTime
            ? DateTime.fromISO(schoolResearcher.visit?.dtStartTime).toFormat(
                "HH:mm"
              )
            : "Não informado"}
        </Typography>
        <Divider />
      </Stack>
      <Formik
        initialValues={initialValues}
        validationSchema={YupSchema}
        onSubmit={handleOnSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Box component={Form} width="100%">
            <Stack direction="column" justifyContent="center" gap={2}>
              <FormControl fullWidth>
                <Field
                  component={TimePickerInput}
                  name="startTime"
                  label="Horário de Início da Entrevista"
                  required
                />
              </FormControl>
              <FormControl fullWidth>
                <Field
                  component={TimePickerInput}
                  name="endTime"
                  label="Horário de Término da Entrevista"
                  required
                />
              </FormControl>
              <Field
                component={CheckboxGroupInput}
                name="availability"
                title="O diretor ou reposável pela escola estava disponível no horário agendado?"
                options={[
                  { label: "Sim", value: "true" },
                  { label: "Não", value: "false" },
                ]}
                singleSelection={true}
                row={true}
                required
              />
              <Field
                component={CheckboxGroupInput}
                name="interruptions"
                title="A reunião ocorreu sem interrupções?"
                options={[
                  { label: "Sim", value: "true" },
                  { label: "Não", value: "false" },
                ]}
                singleSelection={true}
                row={true}
                required
              />
              <Field
                component={CheckboxGroupInput}
                name="infrastructure"
                title="A infraestrutura da escola (local da reunião) estava adequada?"
                options={[
                  { label: "Sim", value: "true" },
                  { label: "Não", value: "false" },
                ]}
                singleSelection={true}
                row={true}
                required
              />
              <Field
                component={CheckboxGroupInput}
                name="documents"
                title="Todos os documentos e informações solicitadas previamente foram apresentados?"
                options={[
                  { label: "Sim", value: "true" },
                  { label: "Não", value: "false" },
                ]}
                singleSelection={true}
                row={true}
                required
              />
              <Field
                component={CheckboxGroupInput}
                name="collaborative"
                title="O diretor/responsável foi colaborativo e forneceu as informações necessárias?"
                options={[
                  { label: "Sim", value: "true" },
                  { label: "Não", value: "false" },
                ]}
                singleSelection={true}
                row={true}
                required
              />
              <Field
                component={CheckboxGroupInput}
                name="duration"
                title="A duração da reunião foi adequada para coletar todas as informações?"
                options={[
                  { label: "Sim", value: "true" },
                  { label: "Não", value: "false" },
                ]}
                singleSelection={true}
                row={true}
                required
              />
              <Field
                component={CheckboxGroupInput}
                name="prepared"
                title="Você se sentiu bem preparado para realizar a pesquisa?"
                options={[
                  { label: "Sim", value: "true" },
                  { label: "Não", value: "false" },
                ]}
                singleSelection={true}
                row={true}
                required
              />
              <Field
                component={CheckboxGroupInput}
                name="support"
                title="Você recebeu suporte adequado antes e durante a visita?"
                options={[
                  { label: "Sim", value: "true" },
                  { label: "Não", value: "false" },
                ]}
                singleSelection={true}
                row={true}
                required
              />
              <Field
                component={TextInput}
                name="questionsEfficiency"
                label="Como você avalia a eficácia das perguntas do questionário?"
                autoComplete="off"
                fullWidth
                multiline
                rows={4}
              />
              <Field
                component={TextInput}
                name="difficulties"
                label="Você encontrou alguma dificuldade durante a coleta de dados? Se sim, descreva."
                autoComplete="off"
                fullWidth
                multiline
                rows={4}
              />
              <Field
                component={TextInput}
                name="additionalComments"
                label="Comentários Adicionais"
                autoComplete="off"
                fullWidth
                multiline
                rows={4}
              />
              <Button
                variant="contained"
                disabled={isSubmitting}
                fullWidth
                disableElevation
                onClick={submitForm}
              >
                Registrar Feedback
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default Feedback;
