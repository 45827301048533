import React from 'react'

import { Box, Stack, Typography, Divider } from '@mui/material'

import Tabs from '@/common/components/Tabs'

import ByStatus from './ByStatus'
import General from './General'

const tabItems = [
  {
    id: 'geral',
    label: 'Geral por estado',
    render: () => <General />,
  },
  {
    id: 'por_status',
    label: 'Escolas por status',
    render: () => <ByStatus />,
  },
]

const Dashboard: React.FC = () => {
  return (
    <Stack gap={3} borderRadius="12px" bgcolor="white" sx={{ boxShadow: 2 }}>
      <Box pt={2} px={2}>
        <Typography variant="h4" fontWeight="500">
          Dashboard
        </Typography>
      </Box>
      <Divider />
      <Stack gap={2} px={2} pb={2}>
        <Tabs initialTab="geral" items={tabItems} />
      </Stack>
    </Stack>
  )
}

export default Dashboard
