import React from "react";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { Field, Form, Formik } from "formik";

import { Box, Button, MenuItem, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { statesOptions } from "@/data/states";

import { TextInput } from "@/components/Inputs/TextInput";
import { SelectInput } from "@/components/Inputs/SelectInput";

import { IUserFormData } from "./types";

const initialValues: IUserFormData = {
  name: "Jorginho",
  email: "jg@mail.com",
  cellphone: "(11) 11111-1111",
  cpf_cnpj: "111.111.111-11",
  city: "MCZ",
  state: "AL",
  type: "1",
  operating_states: ["AL", "SP"],
};

const YupSchema = Yup.object().shape({
  name: Yup.string().required("O nome é obrigatório"),
  email: Yup.string()
    .email("O e-mail deve ser válido")
    .required("O e-mail é obrigatório"),
  cellphone: Yup.string().required("O telefone é obrigatório"),
  cpf_cnpj: Yup.string().required("O CPF/CNPJ é obrigatório"),
  city: Yup.string().required("A cidade é obrigatória"),
  state: Yup.string().required("O estado é obrigatório"),
  type: Yup.string().required("O tipo é obrigatório"),
  operating_states: Yup.array().min(1, "Selecione pelo menos um estado"),
});

const Edit: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = (values: IUserFormData) => {
    // TODO: edit coordinator
  };

  return (
    <Stack
      gap={4}
      borderRadius="12px"
      bgcolor="white"
      sx={{ boxShadow: 2 }}
      p={2}
    >
      <Typography variant="h4" fontWeight="500">
        Editar Usuário
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={YupSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Box component={Form} width="100%">
            <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
              <Box
                display="grid"
                gridTemplateColumns="repeat(2, minmax(0px, 1fr))"
                gap={2}
              >
                <Field
                  component={TextInput}
                  label="Nome de Usuário"
                  name="name"
                  variant="outlined"
                />
                <Field
                  component={TextInput}
                  label="Email"
                  name="email"
                  type="email"
                  variant="outlined"
                />
                <Field
                  component={TextInput}
                  label="Telefone"
                  name="cellphone"
                  variant="outlined"
                />
                <Field
                  component={TextInput}
                  label="CPF/CNPJ"
                  name="cpf_cnpj"
                  variant="outlined"
                />
                <Field
                  component={TextInput}
                  label="Cidade"
                  name="city"
                  variant="outlined"
                />
                <Field
                  component={SelectInput}
                  label="Estado"
                  name="state"
                  variant="outlined"
                  inputProps={{ MenuProps: { style: { maxHeight: 300 } } }}
                >
                  {statesOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  component={SelectInput}
                  label="Tipo"
                  name="type"
                  variant="outlined"
                  inputProps={{ MenuProps: { style: { maxHeight: 300 } } }}
                >
                  <MenuItem value={1}>Opção 1</MenuItem>
                  <MenuItem value={2}>Opção 2</MenuItem>
                </Field>
                <Field
                  component={SelectInput}
                  label="Estados de atuação"
                  name="operating_states"
                  variant="outlined"
                  multiple
                  inputProps={{ MenuProps: { style: { maxHeight: 300 } } }}
                >
                  {statesOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </Box>

              <Stack direction="row" justifyContent="space-between">
                <Button
                  onClick={() => navigate("/gerenciamento/gestor/usuarios")}
                >
                  Voltar
                </Button>
                <Box>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    sx={{ borderRadius: 20 }}
                    onClick={submitForm}
                    loading={isSubmitting}
                  >
                    Editar
                  </LoadingButton>
                </Box>
              </Stack>
            </Stack>
          </Box>
        )}
      </Formik>
    </Stack>
  );
};

export default Edit;
