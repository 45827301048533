import React from "react";

import { Download } from "@mui/icons-material";
import { Stack, Button, IconButton, Grid } from "@mui/material";

import DataTable from "@/common/components/DataTable";
import InfoBox from "@/components/InfoBox";

const DownloadAnswer = ({ id }: { id: number }) => {
  const handleDownload = () => {
    // TODO: download answer
  };

  return (
    <IconButton color="success" onClick={handleDownload}>
      <Download />
    </IconButton>
  );
};

const headers = [
  {
    name: "Nome",
    key: "name",
  },
  {
    name: "Pendentes",
    key: "pending",
  },
  {
    name: "Agendadas",
    key: "scheduled",
  },
  {
    name: "Entrevistadas",
    key: "interviewed",
  },
  {
    name: "Finalizadas",
    key: "finished",
  },
  {
    name: "Respostas",
    key: "answers",
    type: "element",
    align: "center",
  },
];

const General: React.FC = () => {
  const tableData = [
    {
      id: 1,
      name: "Nome 1",
      pending: 1,
      scheduled: 1,
      interviewed: 1,
      finished: 1,
      answers: <DownloadAnswer id={1} />,
    },
    {
      id: 2,
      name: "Nome 2",
      pending: 1,
      scheduled: 1,
      interviewed: 1,
      finished: 1,
      answers: <DownloadAnswer id={2} />,
    },
  ];

  return (
    <Stack gap={3} px={2} pb={2}>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <InfoBox title="Pendentes" value={2} percentage={0.17} />
        </Grid>
        <Grid item md={3}>
          <InfoBox title="Agendadas" value={2} percentage={0.17} />
        </Grid>
        <Grid item md={3}>
          <InfoBox title="Entrevistadas" value={2} percentage={0.17} />
        </Grid>
        <Grid item md={3}>
          <InfoBox title="Finalizadas" value={2} percentage={0.17} />
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="end">
        <Button variant="contained">Baixar todas as respostas</Button>
      </Stack>
      <DataTable headers={headers} data={tableData} />
    </Stack>
  );
};

export default General;
